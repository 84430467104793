<template>
  <div class="box-card">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="账号信息" name="账号信息">
        <el-form :rules="accountFormRules" :model="accountForm" ref="accountForm" class="account-form">
          <div class="item">
            <div class="label">头像</div>
            <div class="tips">请上传真实得体的头像（照片大小不超过10MB）</div>
            <div class="content">
              <image-upload class="pic" v-model="accountForm.avatarImg"
                            :is-show-tip="false" :file-size="10"
                            :limit="1" />
            </div>
          </div>
          <div class="item">
            <div class="label">称呼</div>
            <div class="tips"></div>
            <div class="content">
              <el-input class="input-nickname" placeholder="请输入称呼" v-model="accountForm.nickName" />
            </div>
          </div>
          <el-form-item>
            <el-button type="primary" class="submit" @click="editUserInfo">确定</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="密码修改" name="密码修改" class="password-pane">
        
        <el-form v-show="showChangePasswordForm" label-width="100px"
                 :rules="changePasswordFormRules" :model="changePasswordForm" ref="changePasswordForm">
          <el-form-item label="当前密码" prop="curPassword">
            <el-input type="password" v-model="changePasswordForm.curPassword" placeholder="请输入当前密码"></el-input>
            <el-link class="link-forgot-password" :underline="false" @click="clickForgotPassword">忘记登录密码</el-link>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input type="password" v-model="changePasswordForm.password" placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item label="确认新密码" prop="passwordConfirm">
            <el-input type="password" v-model="changePasswordForm.passwordConfirm"
                      placeholder="请确认新密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="updatePassword">立即修改</el-button>
          </el-form-item>
        </el-form>
        
        <el-form
            v-show="!showChangePasswordForm" label-width="100px"
            :model="forgotPasswordForm" :rules="forgotPasswordFormRules" ref="forgotPasswordForm"
            class="forgotPasswordForm">
          <el-form-item prop="userPhone" label="手机号">
            <el-input placeholder="请输入手机号" v-model="forgotPasswordForm.userPhone"></el-input>
          </el-form-item>
          <el-form-item prop="smsCode" label="短信验证码">
            <el-input type="text" placeholder="短信验证码" v-model="forgotPasswordForm.smsCode">
              <template slot="append">
                <span class="sms" @click="forgotPasswordFormSendSms">{{ forgotPasswordFormSmsInterval }}</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password" label="输入新密码">
            <el-input type="password" placeholder="请输入新密码" v-model="forgotPasswordForm.password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="clickResetPassword">重置密码</el-button>
          </el-form-item>
        </el-form>
      
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "HiringPlatform1PersonInfo",
  
  data() {
    // 手机的校验方法
    let validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      const phoneNameRule = /^1[3-9][0-9]{9}$/;
      if (phoneNameRule.test(value)) {
        return callback();
      } else {
        return callback(new Error("请输入正确手机号"));
      }
    };
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value !== this.changePasswordForm.password)
        return callback(new Error("密码不一致"));
      callback();
    };
    return {
      activeName: '账号信息',
      
      accountForm: {
        avatarImg: '',
        nickName: '',
      },
      accountFormRules: {},
      
      showChangePasswordForm: true,
      changePasswordForm: {
        curPassword: "",
        password: "",
        passwordConfirm: "",
      },
      changePasswordFormRules: {
        curPassword: [{ required: true, message: "请输入当前密码", trigger: "blur" }],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 5, max: 18, message: '长度在 5 到 18 个字符', trigger: 'blur' }
        ],
        passwordConfirm: [
          { required: true, message: "请确认新密码", trigger: "blur" },
          { min: 5, max: 18, message: '长度在 5 到 18 个字符', trigger: 'blur' },
          { validator: validatePass, trigger: "blur" }
        ],
      },
      
      
      forgotPasswordForm: {
        userPhone: "",
        smsCode: "",
        password: "",
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      forgotPasswordFormRules: {
        userPhone: [{ required: true, validator: validatePhone, trigger: "blur" }],
        smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password: [
          { required: true, message: "请确认新密码", trigger: "blur" },
          { min: 5, max: 18, message: '长度在 5 到 18 个字符', trigger: 'blur' },
        ],
      },
      forgotPasswordFormSmsInterval: "获取验证码",
      forgotPasswordFormSmsIsSend: false,
    };
  },
  
  mounted() {
    // 获取个人信息
    this.getUserInfo(true);
  },
  methods: {
    ...mapActions(['logout']),
    getUserInfo(type = false) {
      let url =
          "/app-api/hiring/user/get?id=" +
          this.$store.getters.getUser.id;
      this.$axios
          .get(url)
          .then((res) => {
            if (res.data.code === 0) {
              if (!res.data.data.wechat_no) {
                res.data.data.wechat_no = "";
              }
              if (!res.data.data.nickName) {
                res.data.data.nickName = "";
              }
              this.accountForm = res.data.data;
              // 更新vuex
              if (type) {
                this.$parent.$parent.$parent.GetUserInfo();
              }
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 修改个人信息
    editUserInfo() {
      this.$refs.accountForm.validate((valid) => {
        if (valid) {
          let url = `/app-api/hiring/user/update`;
          this.$axios
              .put(url, {
                id: this.accountForm.id,
                nickName: this.accountForm.nickName,
                avatarImg: this.accountForm.avatarImg,
              })
              .then((res) => {
                if (res.data.code === 0) {
                  this.$notify.success(res.data.message);
                  // 重新调用 获取用户信息 接口
                  this.getUserInfo(true);
                } else {
                  // 弹出通知框提示登录失败信息
                  this.$notify.error(res.data.message);
                }
              })
              .catch((err) => {
                return Promise.reject(err);
              });
        } else {
          return false;
        }
      });
    },
    toPersonalHome() {
      this.$router.push({ path: "/UserInfo/UserHome" });
    },
    // 修改密码
    updatePassword() {
      if (!this.changePasswordForm.password)
        return this.$modal.alertError("请填写要修改的新密码！");
      if (!this.changePasswordForm.passwordConfirm)
        return this.$modal.alertError("请填写确认的新密码！");
      // 判断2次 密码是否一致
      if (this.changePasswordForm.password !== this.changePasswordForm.passwordConfirm)
        return this.$modal.alertError("2次密码输入不一致!");
      let url = "/app-api/hiring/user/update-password";
      this.$axios
          .put(url, {
            oldPassword: this.changePasswordForm.curPassword,
            newPassword: this.changePasswordForm.passwordConfirm
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.$notify.success('密码修改成功，请重新登录！');
              this.logout();
              setTimeout(()=> {
                this.$router.push({ name: 'login' })
              }, 1000)
            } else {
              this.$refs.changePasswordForm.resetFields();
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    handleClick(tab) {
      if ('密码修改' === tab.name) {
        this.$refs.forgotPasswordForm.resetFields()
        this.showChangePasswordForm = true
      }
    },
    clickForgotPassword() {
      this.$refs.changePasswordForm.resetFields()
      this.showChangePasswordForm = false
    },
    clickResetPassword() {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs.forgotPasswordForm.validate((valid) => {
        //如果通过校验开始登录
        if (valid) {
          this.$axios
              .post(`/app-api/hiring/auth/forget-password`, {
                mobile: this.forgotPasswordForm.userPhone,
                code: this.forgotPasswordForm.smsCode,
                password: this.forgotPasswordForm.password
              })
              .then((res) => {
                // “0”代表登录成功，其他的均为失败
                if (res.data.code === 0) {
                  this.$notify.success('密码修改成功，请重新登录！');
                  this.logout();
                  setTimeout(()=> {
                    this.$router.push({ name: 'login' })
                  }, 1000)
                } else {
                  this.$refs.forgotPasswordForm.resetFields();
                  // this.$notify.error(res.data.message);
                }
              })
              .catch((err) => {
                return Promise.reject(err);
              });
        } else {
          return false;
        }
      });
    },
    forgotPasswordFormSendSms() {
      if (this.forgotPasswordForm.userPhone === "") {
        return this.$message.warning("请输入手机号");
      }
      if (!this.forgotPasswordForm.userPhone.match(/^1[3-9][0-9]{9}$/)) {
        return this.$message.warning("请输入正确手机号");
      }
      // 已发送验证码，返回
      if (this.forgotPasswordFormSmsIsSend) return;
      this.forgotPasswordFormSmsIsSend = true;
      let sms_interval_time = 60;
      this.forgotPasswordFormSmsInterval = "发送中...";
      this.$axios
          .post(`/app-api/hiring/auth/send-sms-code-forget-password`, { mobile: this.forgotPasswordForm.userPhone })
          .then((res) => {
            if (res.data.code === 0) {
              this.$message.success('短信发送成功');
              let timer = setInterval(() => {
                if (sms_interval_time <= 1) {
                  clearInterval(timer);
                  this.forgotPasswordFormSmsInterval = "获取验证码";
                  this.forgotPasswordFormSmsIsSend = false; // 重新回复点击发送功能的条件
                } else {
                  sms_interval_time -= 1;
                  this.forgotPasswordFormSmsInterval = `${sms_interval_time}秒后再发`;
                }
              }, 1000);
            } else {
              this.$message.error('短信发送失败，请稍后再试。。。');
              this.forgotPasswordFormSmsInterval = "获取验证码";
            }
          });
    },
  },
};
</script>

<style lang="less" scoped>
.box-card {
  padding: 20px 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 4px 1px rgba(204, 226, 237, 0.5)
}

.account-form {
  .el-input, .el-button {
    width: 240px;
  }
  
  .item {
    margin: 16px 0;
    
    .label, .tips, .content {
      margin: 10px 0;
    }
    
    .label {
      font-weight: bold;
    }
    
    .tips {
      font-size: 14px;
      color: #999999;
    }
  }
}
.password-pane {
  .el-input, .el-button {
    width: 240px;
  }
  
  .link-forgot-password {
    font-size: 14px;
    margin-left: 5px;
  }
  .sms {
    cursor: pointer;
  }
}



</style>
